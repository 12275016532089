import React, { useState } from 'react';
import API from '../../Services/API';
import { 
  Box, FormControl, FormLabel, Input, Button, Text, Image, Card, CardBody 
} from '@chakra-ui/react';
import Logo from './images/logo.png';
import LoaderSpinner from '../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import Styles from './Login.module.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const loginHandler = async () => {
    setIsLoading(true);
    if (!email || !password) {
      setPasswordError("Please provide email and password");
      setIsLoading(false);
      return;
    }

    const body = { email, password };

    try {
      const response = await API.post(`/user-login`, body);
      const token = response.data.access;

      const userResponse = await API.get(`/user-management`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });

      window.localStorage.setItem('user', JSON.stringify(userResponse.data));
      window.localStorage.setItem('sessionId',(response.data.session_id));
      window.localStorage.setItem("accessToken", response.data.access);
      window.localStorage.setItem("refreshToken", response.data.refresh);
      window.location.href = "/dashboard";
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'An error occurred';
      if (errorMessage === 'User not found' || errorMessage === 'Incorrect password') {
        setPasswordError("Invalid credentials. Please try again with the correct email and password.");
      } else {
        setPasswordError("An unexpected error occurred. Please try again later.");
      }
      setIsLoading(false);
    }
  };

  return (
    <Box className={Styles.limiter}>
      <Box className={Styles.container_login100}>
        <Box className={Styles.wrap_login100}>
          {isLoading && <LoaderSpinner />}
          <Box textAlign="center" w="100%">
            <Image src={Logo} m="0 auto" alt="Logo" />
          </Box>
          <Card w="100%">
            <CardBody>
              <Text className={Styles.text_login}>
                Please login using your work email
              </Text>
              <Text className={Styles.error}>
                {passwordError}
              </Text>
              <FormControl>
              <FormLabel htmlFor="email" className={Styles.text_login}>Username / Email</FormLabel>
  <Input 
    id="email"
    type="email" 
    mb="15px" 
    value={email}
    onChange={(e) => setEmail(e.target.value)} 
    placeholder="user@company.com" 
    aria-describedby="email-helper-text"
  />
  
  <FormLabel htmlFor="password" className={Styles.text_login}>Password</FormLabel>
  <Input 
    id="password"
    type="password" 
    mb="15px" 
    value={password}
    onChange={(e) => setPassword(e.target.value)} 
    placeholder="password" 
    aria-describedby="password-helper-text"
  />
  
  
                <Button
                    className={Styles.loginButton}
                    colorScheme="blue" 
                    color="#ffffff"  // Text color (white)
                    bg="#0056b3"     // Darker blue background for higher contrast
                    _hover={{ bg: "#004494" }} // Darker hover effect for accessibility
                    onClick={loginHandler}
                >
                    Login
                </Button>
              </FormControl>
            </CardBody>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;