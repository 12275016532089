import React, { useContext, useState } from "react";
import {
  Box,
  Text,
  Select,
  Input,
  Button,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../../../Services/API";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import { EditContext } from "../../../../Context/BreadcrumbsContext";

const RadioTabThree = () => {
  const token = window.localStorage.getItem("accessToken");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { hasAllPermission } = useContext(EditContext);
  
  const toast = useToast(); 
console.log("hasAllPermission2",hasAllPermission)

  const getFormattedDate = (currentDate) => {
    return (
      currentDate.getFullYear() +
      "-" +
      String(currentDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(currentDate.getDate()).padStart(2, "0")
    );
  };
  const getImpactStoriesReport = async () => {
    setIsLoading(true);
    try {
      const start_Date = getFormattedDate(startDate);
      // const end_Date = getFormattedDate(endDate);

      const response = await API.get(
        `/dau?date=${start_Date}`,
        // `stories?start_date=${start_Date}&end_date=${end_Date}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const data = response.data;
      console.log("DAU", response.data);
      if (response?.data?.length === 0) {
        toast({
          title: "NO data found.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
        setIsPopupOpen(false);
        return;
      }
      toast({
        title: "Report fetched successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Remove underscores from header names and adjust styles
      const processHeaders = (data) => {
        const headers = Object.keys(data[0]);
        const formattedHeaders = headers.map(
          (header) => header.replace(/_/g, " ") // Replace underscores with spaces
        );

        // Create a new array with formatted headers
        const newData = data.map((row) =>
          Object.fromEntries(
            headers.map((header, index) => [
              formattedHeaders[index],
              row[header],
            ])
          )
        );

        return { formattedHeaders, newData };
      };

      // Format headers and data
      const { formattedHeaders, newData } = processHeaders(data);

      // Create a worksheet from the data
      const ws = XLSX.utils.json_to_sheet(newData);

      // Dynamically calculate column widths
      const colWidths = formattedHeaders.map((col) => {
        const maxLength = Math.max(
          ...newData.map((row) => (row[col] ? row[col].toString().length : 0)),
          col.length // Include column header length
        );
        return { wch: maxLength + 2 }; // Add padding to width
      });
      ws["!cols"] = colWidths; // Apply column widths to worksheet

      // Apply header styling
      formattedHeaders.forEach((header, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex }); // Header cell address
        ws[cellAddress].s = {
          fill: { fgColor: { rgb: "000000" } }, // Black background
          font: {
            color: { rgb: "FFFFFF" },
            bold: true,
            sz: 12,
            name: "Arial",
            family: 2,
          }, // White, bold text with font size
          alignment: { horizontal: "center", vertical: "center" }, // Center alignment
        };

        // Ensure text is in uppercase
        if (ws[cellAddress].v) {
          ws[cellAddress].v = ws[cellAddress].v.toUpperCase();
        }
      });

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "DAU Report");
      XLSX.writeFile(wb, "DAU_report.xlsx");
      setStartDate(null)
      setIsLoading(false);
      setIsPopupOpen(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setIsLoading(false);
      setIsPopupOpen(false);
    }
  };

  const handleDownloadReport = () => {
    if (!startDate) {
    // if (!startDate || !endDate) {
      setErrorMessage("Date are required.");
      return;
    }
    setErrorMessage("");
    setIsPopupOpen(true); // Show the popup
  };
  return (
    <Box mt="0" paddingInline="18">
      {isLoading && <LoaderSpinner />}
      <Box p="4" borderWidth="1px" borderRadius="md">
        <Text fontSize="md" mb={0}>
          The summary report of all Daily Users (distinct, returning) per day
          for the Digital Solution, Elapsed Total Time per average per CEC.
        </Text>
      </Box>

      <Stack direction="row" pt={3}>
        <FormControl isRequired>
          <FormLabel>Select Date</FormLabel>
          <DatePicker
            placeholderText={"yyyy/mm/dd"}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy/mm/dd"
            customInput={<Input placeholder="yyyy/mm/dd" w="130px" />}
          />
        </FormControl>
        {/* <FormControl isRequired>
          <FormLabel>End Date</FormLabel>
          <DatePicker
            placeholderText={"yyyy/mm/dd"}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy/mm/dd"
            customInput={<Input placeholder="yyyy/mm/dd" w="130px" />}
          />
        </FormControl> */}
        <FormControl sx={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            sx={{
              backgroundColor: "#00a0da",
              _hover: {
                backgroundColor: "blue.100", // Change to your desired hover color
              },
            }}
            leftIcon={<DownloadIcon />}
            colorScheme="blackAlpha"
            size="md"
            onClick={handleDownloadReport}
          >
            Download XLS Report
          </Button>
        </FormControl>
      </Stack>

      {errorMessage && (
        <Box mt={2} color="red.500">
          <Text>{errorMessage}</Text>
        </Box>
      )}

      {/* Popup Modal */}
      <Modal isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <VStack>
                <Text mt={4}>Fetching data... Please wait.</Text>
              </VStack>
            ) : (
              <Text>
                The report has been generated Download should begin shortly.
              </Text>
            )}
          </ModalBody>
          <ModalFooter
            sx={{ background: "transparent", justifyContent: "center" }}
          >
            {!isLoading && (
              <Button
                sx={{ background: "#000", color: "#fff" }}
                onClick={getImpactStoriesReport}
              >
                OK
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RadioTabThree;
