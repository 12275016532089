// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import API from '../../../Services/API';
// import {
//     Table,
//     Thead,
//     Tbody,
//     Tr,
//     Th,
//     Td,
//     TableContainer,
//     Box,
//     Button,
//     Stack
// } from '@chakra-ui/react';
// import {
//     Alert,
//     AlertIcon,
//     AlertTitle,
//     AlertDescription,
//   } from '@chakra-ui/react'
// import TableToCSV from './TableToCSV';
// import LogsLoader from './LogsLoader';
// import Pagination from "react-js-pagination";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import moment from 'moment';

// const Logs = () => {
//     const tableRef = useRef(null);
//     const token = window.localStorage.getItem("accessToken");
//     const [isLoading, setIsLoading] = useState(false);
//     const [log, setLog] = useState([]);
//     const [activePage, setActivePage] = useState(1);
//     const [logCount, setLogCount] = useState(0);

//     // States for date selection
//     const [tempStartDate, setTempStartDate] = useState(null);
//     const [tempEndDate, setTempEndDate] = useState(null);

//     // State used for filtering and API fetching
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);
//     const [downloadLogs, setDownloadLogs] = useState([]);

//     const itemsPerPage = 100; // Adjust this if your items per page changes

//     const fetchLogs = useCallback((pageNumber) => {
//         setIsLoading(true);

//         const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
//         const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';

//         const queryParams = new URLSearchParams({
//             page: pageNumber,
//             start_date: formattedStartDate,
//             end_date: formattedEndDate,
//         }).toString();

//         // Fetch logs
//         API.get(`/test-log?${queryParams}`, {
//             headers: {
//                 'Authorization': `Bearer ${token}`
//             }
//         }).then((response) => {
//             setLogCount(response?.data?.count || 0);
//             setLog(response?.data?.results || []);
//             setIsLoading(false);

//             // Conditionally call /download-log if start_date and end_date are empty
//             if ((formattedStartDate !== '') && (!formattedEndDate !== '')) {
//                 API.get(`/download-log?${queryParams}`, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`
//                     }
//                 }).then((response) => {
//                     setDownloadLogs(response.data);
//                 }).catch(error => {
//                     console.error("Error fetching download logs:", error);
//                 });
//             } else {
//                 API.get(`/download-log`, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`
//                     }
//                 }).then((response) => {
//                     setDownloadLogs(response.data);
//                 }).catch(error => {
//                     console.error("Error fetching download logs:", error);
//                 });
//             }
//         }).catch(error => {
//             setIsLoading(false);
//             console.error("Error fetching logs:", error);
//         });
//     }, [startDate, endDate, token]);

//     useEffect(() => {
//         fetchLogs(activePage);
//     }, [activePage, fetchLogs]);

//     const handlePageChange = (pageNumber) => {
//         setActivePage(pageNumber);
//     };

//     const handleDateFilter = () => {
//         setStartDate(tempStartDate);
//         setEndDate(tempEndDate);
//         setActivePage(1); // Reset to the first page
//     };

//     const handleResetFilters = () => {
//         // Reset date filters
//         setTempStartDate(null);
//         setTempEndDate(null);
//         setStartDate(null);
//         setEndDate(null);
//         setActivePage(1); // Reset to the first page
//     };

//     return (
//         <>
//             <Box mb="20px" display="inline-block" borderRadius="md" p="2" border="1px solid" borderColor="gray.300" mr={'15px'}>
//                 <DatePicker
//                     selected={tempStartDate}
//                     onChange={(date) => setTempStartDate(date)}
//                     placeholderText="Start Date (mm/dd/yyyy)"
//                     dateFormat="MM/dd/yyyy"
//                 />
//             </Box>
//             <Box mb="20px" display="inline-block" borderRadius="md" p="2" border="1px solid" borderColor="gray.300">
//                 <DatePicker
//                     selected={tempEndDate}
//                     onChange={(date) => setTempEndDate(date)}
//                     placeholderText="End Date (mm/dd/yyyy)"
//                     dateFormat="MM/dd/yyyy"
//                     minDate={tempStartDate}
//                 />
//             </Box>
//             <Box mb="20px" display="inline-block" borderRadius="md" ml="10px">
//                 <Button onClick={handleDateFilter} ml="0px" className='applyFil'>
//                     Apply Filter
//                 </Button>
//                 <Button onClick={handleResetFilters} ml="10px" className='resetFil'>
//                     Reset Filters
//                 </Button>
//             </Box>
//             <Box>
//                 <Pagination
//                     activePage={activePage}
//                     itemsCountPerPage={itemsPerPage}
//                     totalItemsCount={logCount}
//                     pageRangeDisplayed={5}
//                     onChange={handlePageChange}
//                 />
//                 <TableToCSV tableData={tableRef.current} filename="table_data.csv" />
//                 <Box w={'100%'}>
//                     <Stack spacing={3} w={'100%'}>
//                         <Alert status='info' fontSize={'13px'}>
//                             <AlertIcon />
//                             The maximum download limit is the first/earliest 10,000 entries. For a more streamlined & better experience, we recommend narrowing the date range to reduce the number of entries.
//                         </Alert>
//                     </Stack>
//                 </Box>

//             </Box>

//             <TableContainer mt={'15px'} mb={'15px'} backgroundColor={'#ffffff'} width={'100%'} className='d-none'>
//                 <Table size='sm' colorScheme='teal' border="2px solid" borderColor="gray.200" rounded="md" ref={tableRef}>
//                     <Thead backgroundColor={'#00aae0'} color={'#ffffff'}>
//                         <Tr>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">S.No</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Action Id</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Date</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Time</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">User Name</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">User Email</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Action Performed</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Action Details</Th>
//                         </Tr>
//                     </Thead>
//                     <Tbody>
//                         {isLoading ? (
//                             <Tr>
//                                 <Td colSpan="8" textAlign="center">
//                                     <LogsLoader />
//                                 </Td>
//                             </Tr>
//                         ) : (
//                             downloadLogs && downloadLogs.length === 0 ? (
//                                 <Tr>
//                                     <Td colSpan="8" textAlign="center">No logs available</Td>
//                                 </Tr>
//                             ) : (
//                                 downloadLogs.length>0 && downloadLogs.map((item) => (
//                                     <Tr key={item.id}>
//                                         <Td textAlign="center"> {item.id} </Td>
//                                         <Td textAlign="center"> {moment(item.date).format('MM/DD/YYYY')}</Td>
//                                         <Td textAlign="center"> {item.time} </Td>
//                                         <Td textAlign="center"> {item.username} </Td>
//                                         <Td textAlign="center"> {item.user_email} </Td>
//                                         <Td textAlign="center"> {item.action_performed} </Td>
//                                         <Td textAlign="center"> {item.action_type} </Td>
//                                     </Tr>
//                                 ))
//                             )
//                         )}
//                     </Tbody>
//                 </Table>
//             </TableContainer>

//             <TableContainer mt={'15px'} mb={'15px'} backgroundColor={'#ffffff'} width={'100%'}>
//                 <Table size='sm' colorScheme='teal' border="2px solid" borderColor="gray.200" rounded="md">
//                     <Thead backgroundColor={'#00aae0'} color={'#ffffff'}>
//                         <Tr>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">S.No</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Action Id</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Date</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Time</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">User Name</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">User Email</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Action Performed</Th>
//                             <Th className={'colorWhite'} p={'10px 15px'} textAlign="center">Action Details</Th>
//                         </Tr>
//                     </Thead>
//                     <Tbody>
//                         {isLoading ? (
//                             <Tr>
//                                 <Td colSpan="8" textAlign="center">
//                                     <LogsLoader />
//                                 </Td>
//                             </Tr>
//                         ) : (
//                             log.length === 0 ? (
//                                 <Tr>
//                                     <Td colSpan="8" textAlign="center">No logs available</Td>
//                                 </Tr>
//                             ) : (
//                                 log.map((item, index) => (
//                                     <Tr key={item.id}>
//                                         <Td textAlign="center"> {(activePage - 1) * itemsPerPage + index + 1} </Td>
//                                         <Td textAlign="center"> {item.id} </Td>
//                                         <Td textAlign="center"> {moment(item.date).format('MM/DD/YYYY')}</Td>
//                                         <Td textAlign="center"> {item.time} </Td>
//                                         <Td textAlign="center"> {item.username} </Td>
//                                         <Td textAlign="center"> {item.user_email} </Td>
//                                         <Td textAlign="center"> {item.action_performed} </Td>
//                                         <Td textAlign="center"> {item.action_type} </Td>
//                                     </Tr>
//                                 ))
//                             )
//                         )}
//                     </Tbody>
//                 </Table>
//             </TableContainer>

//             <Box display="flex" justifyContent="center" mb="20px">
//                 <Pagination
//                     activePage={activePage}
//                     itemsCountPerPage={itemsPerPage}
//                     totalItemsCount={logCount}
//                     pageRangeDisplayed={5}
//                     onChange={handlePageChange}
//                 />
//             </Box>
//         </>
//     );
// };

// export default Logs;
import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RadioTabone from "./RadiotabUI/RadioTabone";
import RadioTabtwo from "./RadiotabUI/RadioTabtwo";
import RadioTabThree from "./RadiotabUI/RadioTabthree";
import RadioTabFour from "./RadiotabUI/RadioTabFour";
import RadioTabFive from "./RadiotabUI/RadioTabFive";
import RadioTabSix from "./RadiotabUI/RadioTabthreeSix";

const ReportPage = () => {
  const [reportType, setReportType] = useState("System and Application Availability Report");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [uptimeMin, setUptimeMin] = useState(0);
  const [uptimeMax, setUptimeMax] = useState(100);

  // Function to render UI based on selected report type
  const renderRightSideUI = () => {
    switch (reportType) {
      case "System and Application Availability Report":
        return <RadioTabone />;

      case "User Login Report":
        return <RadioTabtwo />;

      case "Daily Active Users (DAU)":
        return <RadioTabThree />;

      case "Monthly Active Users (MAU)":
        return <RadioTabFour />;

      case "Impact Stories":
        return <RadioTabFive />;

      case "Tagging Report":
        return <RadioTabSix />;

      default:
        return (
          <Text>
            Select a report type to see its details.
          </Text>
        );
    }
  };

  return (
    <Box pt={10} bg="white" minH="70vh" display="flex" gap={8}>
      {/* Left Side - Report Type Selection */}
      <Box flex="1">
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          Report Type
        </Text>
        <RadioGroup
          value={reportType}
          onChange={(value) => setReportType(value)}
        >
          <Stack direction="column">
            <Radio  value="System and Application Availability Report">
              System and Application Availability Report
            </Radio>
            <Radio value="User Login Report">User Login Report</Radio>
            <Radio value="Daily Active Users (DAU)">
              Daily Active Users (DAU)
            </Radio>
            <Radio value="Monthly Active Users (MAU)">
              Monthly Active Users (MAU)
            </Radio>

            <Radio value="Impact Stories">Impact Stories</Radio>
            <Radio value="Tagging Report">Tagging Report</Radio>
          </Stack>
        </RadioGroup>
      </Box>

      <Box flex="2">{renderRightSideUI()}</Box>
    </Box>
  );
};

export default ReportPage;
